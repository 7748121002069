import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
	additionalServicesBasedOnLocation,
	commonPackages,
} from '../data/servicesData';

// subsections
import Footer from '../sections/pageSections/footer';
import FormContent from './subsections/formContent';

const SalesForm = () => {
	const navigate = useNavigate();
	const methods = useForm();
	const { handleSubmit, setValue, getValues, watch, control, register } =
		methods;
	const [selectedLocation, setSelectedLocation] = useState('');
	const [selectedServices, setSelectedServices] = useState({});
	const [userDepartment, setUserDepartment] = useState('');
	const [sending, setSending] = useState(false);
	const { id } = useParams();

	const apiUrl = process.env.REACT_APP_API_URL;
	console.log('API URL:', apiUrl);

	const decodeBase64Url = (base64Url) => {
		base64Url = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const pad = base64Url.length % 4;
		if (pad) {
			if (pad === 1) {
				throw new Error(
					'InvalidLengthError: Input base64url string is the wrong length to determine padding'
				);
			}
			base64Url += new Array(5 - pad).join('=');
		}
		return decodeURIComponent(
			atob(base64Url)
				.split('')
				.map(
					(c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
				)
				.join('')
		);
	};

	useEffect(() => {
		// If an ID is present in the URL, attempt to recover the form data
		if (id) {
			const loadSavedFormData = async () => {
				try {
					const response = await axios.get(
						`${apiUrl}/form/recover/${id}`
					);
					const savedData = response.data;
					console.log('Recovered form data:', savedData); // Add this line for debugging
					Object.keys(savedData).forEach((key) =>
						setValue(key, savedData[key])
					);
				} catch (error) {
					console.error('Error loading saved form data:', error);
				}
			};

			loadSavedFormData();
		}
	}, [id, setValue, apiUrl]);

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			const base64Url = token.split('.')[1];
			const decodedPayload = decodeBase64Url(base64Url);
			const user = JSON.parse(decodedPayload);
			if (user && user.department) {
				setUserDepartment(user.department);
				setSelectedLocation(user.department);
				setValue('selectedLocation', user.department);
			}
		}
	}, [setValue]);

	const formData = watch();

	const handleLocationSelect = (location) => {
		setSelectedLocation(location);
		setValue('selectedLocation', location);
		console.log(location);
	};

	const handleServiceChange = (serviceId, subPackageId = null, isChecked) => {
		setSelectedServices((prev) => {
			const currentServiceDetails = prev[serviceId] || {};
			if (subPackageId) {
				const currentSubPackages =
					currentServiceDetails.subPackages || {};
				currentSubPackages[subPackageId] = isChecked;
				currentServiceDetails.subPackages = currentSubPackages;
			} else {
				currentServiceDetails.isChecked = isChecked;
			}
			return { ...prev, [serviceId]: currentServiceDetails };
		});
	};
	console.log('Selected Services State:', selectedServices);

	const storeError = (errorData) => {
		const errors = localStorage.getItem('frontendErrors');
		const parsedErrors = errors ? JSON.parse(errors) : [];
		parsedErrors.push(errorData);
		localStorage.setItem('frontendErrors', JSON.stringify(parsedErrors));
	};

	const logErrorToBackend = (errorData) => {
		fetch(`${process.env.REACT_APP_API_URL}/log/frontend-error`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(errorData),
		}).catch((err) =>
			console.error('Error logging frontend error to backend:', err)
		);
	};

	const saveFormData = async (data) => {
		try {
			const response = await axios.post(`${apiUrl}/form/save`, {
				formData: data,
			});
			const { savedFormId } = response.data;
			const savedFormUrl = `${window.location.origin}/form/recover/${savedFormId}`;
			alert(
				`An error occurred. Your form has been saved. Use this link to recover: ${savedFormUrl}`
			);
		} catch (error) {
			console.error('Error saving form data:', error);
		}
	};

	const onSubmit = async (data) => {
		const packageChoice = data.packageChoice;
		setSending(true);

		console.log('Final selected services state:', selectedServices);
		const findServiceOrSubPackageById = (serviceId) => {
			for (const location of Object.values(
				additionalServicesBasedOnLocation
			)) {
				for (const packages of Object.values(location)) {
					const foundService = packages.find(
						(service) => service.id === serviceId
					);
					if (foundService) return foundService;
				}
			}

			for (const packages of Object.values(commonPackages)) {
				const foundService = packages.find(
					(service) => service.id === serviceId
				);
				if (foundService) return foundService;
				for (const service of packages.filter(
					(service) => service.subPackages
				)) {
					const foundSubPackage = service.subPackages.find(
						(sub) => sub.id === serviceId
					);
					if (foundSubPackage) return foundSubPackage;
				}
			}

			return null;
		};

		const selectedServicesDetails = Object.entries(selectedServices)
			.filter(
				([_, details]) =>
					details.isChecked ||
					Object.values(details.subPackages || {}).some((v) => v)
			)
			.map(([serviceId, details]) => {
				const service = findServiceOrSubPackageById(serviceId);
				console.log('Processing service:', serviceId, details);
				if (!service) {
					console.error('Service not found:', serviceId);
					return { name: '', subPackages: [] };
				}

				const subPackageNames = Object.entries(
					details.subPackages || {}
				)
					.filter(([_, isChecked]) => isChecked)
					.map(([subPackageId, _]) => {
						const subPackage =
							service.subPackages &&
							service.subPackages.find(
								(sub) => sub.id === subPackageId
							);
						if (!subPackage) {
							console.error(
								'Sub-package not found:',
								subPackageId,
								'in service',
								serviceId
							);
						}
						return subPackage ? subPackage.name : undefined;
					})
					.filter((name) => name);

				return { name: service.name, subPackages: subPackageNames };
			});

		console.log('Selected services details:', selectedServicesDetails);

		const submissionData = {
			...data,
			additionalServices: selectedServices,
		};

		console.log('Submission Data:', submissionData);

		try {
			const token = localStorage.getItem('token');
			const formData = new FormData();

			formData.append('formData', JSON.stringify(data));

			const files = getValues('companyProfile');
			files.forEach((file) => formData.append('companyProfile', file));

			// Simulate an error by using the wrong API endpoint
			const response = await fetch(`${apiUrl}/form/submit-form`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			if (response.ok) {
				const responseData = await response.json();
				console.log('Success:', responseData);
				navigate('/review');
				setSending(false); // Reset sending state
			} else {
				console.error(
					'Server responded with error:',
					response.statusText
				);
				const errorData = {
					message: 'Could not send form: Unauthorized',
					source: 'Form submission',
					lineno: 0,
					colno: 0,
					error: 'Unauthorized access',
					userAgent: navigator.userAgent,
					timestamp: new Date().toISOString(),
				};
				storeError(errorData);
				logErrorToBackend(errorData);
				throw new Error('Form submission failed');
			}
		} catch (error) {
			console.error('Catch block triggered with error:', error);
			const errorData = {
				message: error.message,
				source: window.location.href,
				lineno: error.lineNumber || 0,
				colno: error.columnNumber || 0,
				error: error.stack || null,
				userAgent: navigator.userAgent,
				timestamp: new Date().toISOString(),
			};

			// Save the error to frontend errors
			storeError(errorData);
			logErrorToBackend(errorData);

			// Reset sending state
			setSending(false);

			// Attempt to save the form data
			console.log('Attempting to save form data:', data);
			await saveFormData(data);
			console.log('Form data should now be saved.');

			// Notify the user
			alert('An error occurred. Your form has been saved.');
		}
	};

	const onTest = () => {
		navigate('/review');
	};

	const handleChange = (name, value) => {
		setValue(name, value);
		console.log(formData);
	};

	return (
		<FormProvider {...methods}>
			<form className='w-2/3 mx-auto' onSubmit={handleSubmit(onSubmit)}>
				<FormContent
					control={control}
					register={register}
					handleChange={handleChange}
					formData={formData}
					setValue={setValue}
					selectedLocation={selectedLocation}
					handleLocationSelect={handleLocationSelect}
					selectedServices={selectedServices}
					setSelectedServices={setSelectedServices}
					getValues={getValues}
					preselectedLocation={userDepartment}
					sending={sending}
				/>
				<input type='hidden' {...register('selectedLocation')} />
				<Footer />
			</form>
		</FormProvider>
	);
};

export default SalesForm;
