import React, { useEffect, useState } from 'react';
import ButtonSelection from '../../components/ButtonSelection';
import CheckboxComponent from '../../components/CheckboxComponent';
import InputComponent from '../../components/InputComponent';
import ClientInfo from '../subsections/ClientInfo';

// SCSS
import '../../assets/scss/sectionOne.scss';

const base64UrlDecode = (str) => {
	try {
		return decodeURIComponent(
			atob(str)
				.split('')
				.map(function (c) {
					return (
						'%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
					);
				})
				.join('')
		);
	} catch (e) {
		console.error('Error decoding base64:', e);
		return null;
	}
};

const parseJwt = (token) => {
	try {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = base64UrlDecode(base64);
		return JSON.parse(jsonPayload);
	} catch (e) {
		console.error('Error parsing JWT:', e);
		return null;
	}
};

const SectionOne = ({
	control,
	register,
	handleLocationSelect,
	handleChange,
	formData,
	setValue,
}) => {
	const [department, setDepartment] = useState('');

	useEffect(() => {
		// Retrieve and parse the token from localStorage
		const token = localStorage.getItem('token');
		if (token) {
			const user = parseJwt(token);
			console.log('User data from token:', user); // Logging user data
			if (user && user.department) {
				setDepartment(user.department);
				setValue('selectedLocation', user.department); // Set the initial value
			}
		}
	}, [setValue]);

	const localHandleLocationSelect = (location) => {
		handleLocationSelect(location);
		setValue('selectedLocation', location);
	};

	return (
		<div className='sectionOne flex-col gap-40'>
			<ClientInfo control={control} register={register} />
			<div className='flex-row norgesgruppen'>
				<CheckboxComponent
					label={
						<span>
							Er dette et <b>Norgesgruppen</b>-Selskap?
						</span>
					}
					name='norgesgruppenCheck'
					value='Norgesgruppen'
					className='norgesgruppenCheck'
					subContext='NG tillater ikke retargeting. Dvs. Vi kan ikke
					målrette annonsene mot folk som har kikket
					på tilsvarende annonser, eller folk som har
					sett annonsen en gang, men enda ikke søkt. Vi
					kan kun benytte oss av mer generiske
					målgrupper, basert på demografisk data som
					kjennetegner målgruppen vi ønsker å nå'
					register={register}
				/>
			</div>
			<div className='sok flex-row'>
				<CheckboxComponent
					label={
						<span>
							Search <b>AC</b>
						</span>
					}
					name='searchCheck'
					value='Søk'
					className='searchCheck'
					subContext='Search igangsettes av AC parallelt med kampanjen'
					register={register}
				/>
			</div>

			<div className='flex-row stillBtn'>
				<div className='posTitle'>
					<InputComponent
						label='Stillingstittel (i annonsen)'
						type='text'
						name='stillingstittel'
						required
						register={register}
						onChange={(e) =>
							handleChange(e.target.name, e.target.value)
						}
					/>
				</div>

				<div className='flex-col'>
					<label>Velg kontor:</label>
					<ButtonSelection
						onSelect={localHandleLocationSelect}
						preselectedLocation={department}
					/>
				</div>
			</div>
		</div>
	);
};

export default SectionOne;
