import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import InputComponent from '../../components/InputComponent';

const decodeBase64Url = (base64Url) => {
	// Replace non-url compatible chars with base64 standard chars
	base64Url = base64Url.replace(/-/g, '+').replace(/_/g, '/');

	// Pad out with standard base64 required padding characters
	const pad = base64Url.length % 4;
	if (pad) {
		if (pad === 1) {
			throw new Error(
				'InvalidLengthError: Input base64url string is the wrong length to determine padding'
			);
		}
		base64Url += new Array(5 - pad).join('=');
	}

	return decodeURIComponent(
		atob(base64Url)
			.split('')
			.map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
			.join('')
	);
};

const ClientInfo = () => {
	const { register, setValue } = useFormContext(); // Use useFormContext to access setValue
	const [userData, setUserData] = useState({
		ordererName: '',
		ordererEmail: '',
		ordererPhone: '',
	});
	const [suggestions, setSuggestions] = useState([]);
	const [query, setQuery] = useState('');

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			const base64Url = token.split('.')[1];
			const decodedPayload = decodeBase64Url(base64Url);
			const user = JSON.parse(decodedPayload);

			const userData = {
				ordererName: user.name,
				ordererEmail: user.email,
				ordererPhone: user.telephone,
			};
			setUserData(userData);
			// Update the form state
			setValue('ordererName', userData.ordererName);
			setValue('ordererEmail', userData.ordererEmail);
			setValue('ordererPhone', userData.ordererPhone);
		}
	}, [setValue]);

	const handleQueryChange = async (e) => {
		const value = e.target.value;
		setQuery(value);

		if (value.length > 0) {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/clients/search?q=${value}`
				);
				setSuggestions(response.data);
			} catch (error) {
				console.error('Error fetching client suggestions:', error);
			}
		} else {
			setSuggestions([]);
		}
	};

	const handleSuggestionClick = (suggestion) => {
		setQuery(suggestion.name);
		setValue('companyName', suggestion.name);
		setSuggestions([]);
	};

	return (
		<div className='clientInfo flex flex-row'>
			<div>
				<div className='autocomplete'>
					<InputComponent
						label='Firmanavn kunde'
						type='text'
						name='companyName'
						value={query}
						required
						register={register}
						onChange={handleQueryChange}
						autoComplete='off' // Disable browser autocomplete
					/>
					{suggestions.length > 0 && (
						<ul className='suggestions'>
							{suggestions.map((suggestion, index) => (
								<li
									key={index}
									onClick={() =>
										handleSuggestionClick(suggestion)
									}
								>
									{suggestion.name}
								</li>
							))}
						</ul>
					)}
				</div>
			</div>
			<div>
				<InputComponent
					label='Navn på bestiller AC'
					type='text'
					name='ordererName'
					required
					register={register}
					defaultValue={userData.ordererName}
					readOnly
				/>
			</div>
			<div>
				<InputComponent
					label='Epost til bestiller AC'
					type='email'
					name='ordererEmail'
					required
					register={register}
					defaultValue={userData.ordererEmail}
					readOnly
				/>
			</div>
			<div>
				<InputComponent
					label='Telefon'
					type='tel'
					name='ordererPhone'
					required
					register={register}
					defaultValue={userData.ordererPhone}
				/>
			</div>
		</div>
	);
};

export default ClientInfo;
