import AdrLogo from '../../assets/images/alogo.svg';
import pmedia from '../../assets/images/polarismedia_logo_neg.svg';

import '../../assets/scss/footer.scss';

const Footer = () => {
	return (
		<div className='footer'>
			<div className='container flex-column'>
				<div className='logo'>
					<img src={pmedia} alt='Adresseavisen logo' />
				</div>
				<div className='contact flex-column'>
					<h2>Janne Løvseth</h2>
					<p>
						Telefon: <a href='tel:97715766'>977 15 766</a>
					</p>
					<p>
						Epost:{' '}
						<a href='mailto:janne.lovseth@adresseavisen.no'>
							janne.lovseth@adresseavisen.no
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Footer;
