// Components
import CheckboxComponent from '../../components/CheckboxComponent';
import InputComponent from '../../components/InputComponent';
import TextareaComponent from '../../components/TextareaComponent';

// SCSS
import '../../assets/scss/sectionSix.scss';

const SectionSix = ({ register, formData }) => {
	return (
		<div className='sectionSix malretting flex-column'>
			<div className='flex-row geo'>
				<InputComponent
					label='Geografi'
					type='text'
					name='malGeo'
					register={register}
					className='malGeo'
				/>
				<InputComponent
					label='Alder'
					type='text'
					name='geoAge'
					register={register}
					className='geoAge'
				/>
			</div>
			<>
				<InputComponent
					label='Ønsker / krav til utdanning og / eller arbeidserfaring'
					name='wishesDemands'
					className='wishesDemands'
					register={register}
				/>
			</>
			<>
				<InputComponent
					label='Ferdigheter / stillingstitler'
					name='skillsTitles'
					className='skillsTitles'
					register={register}
				/>
			</>
			<>
				<InputComponent
					label='Bedrifter hvor potensielle kandidater jobber i dag'
					name='companies'
					className='companies'
					register={register}
				/>
			</>
			<>
				<InputComponent
					label='Bransje / interesser'
					name='industryInterests'
					className='industryInterests'
					register={register}
				/>
			</>
			<>
				<CheckboxComponent
					label='Ja, hybrid/hjemmekontor er mulig'
					name='hybridHome'
					value='hybridHome'
					className='hybridHome'
					register={register}
					checked={formData['hybridHome']}
				/>
			</>
		</div>
	);
};

export default SectionSix;
