import React, { useEffect, useState } from 'react';
import { HiPlusSm } from 'react-icons/hi';

const FileUploadComponent = ({ label, name, control, setValue }) => {
	const [selectedFiles, setSelectedFiles] = useState([]);

	useEffect(() => {
		setValue(name, selectedFiles, { shouldValidate: true });
	}, [selectedFiles, name, setValue]);

	const handleFileChange = (e) => {
		const files = Array.from(e.target.files);
		setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
	};

	const handleRemoveFile = (fileName) => {
		setSelectedFiles((prevFiles) =>
			prevFiles.filter((file) => file.name !== fileName)
		);
	};

	return (
		<div className='file-upload-container'>
			<div className='file-upload-container__content'>
				<label htmlFor={name} className='file-upload-label'>
					{label}
				</label>
				<input
					type='file'
					name={name}
					id={name}
					onChange={handleFileChange}
					multiple
					hidden
				/>
				<div
					className='file-upload-custom'
					onClick={() => document.getElementById(name).click()}
				>
					<HiPlusSm size='38' />
				</div>
			</div>
			<div className='selected-files-list'>
				{selectedFiles.map((file) => (
					<div key={file.name} className='file-item'>
						<span className='file-name'>{file.name}</span>
						<button
							type='button'
							className='remove-file-button'
							onClick={() => handleRemoveFile(file.name)}
						>
							Ta bort
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

export default FileUploadComponent;
