import React, { useEffect, useState } from 'react';
import { FaRegFilePdf } from 'react-icons/fa6';
import '../../assets/scss/orders.scss';

const Orders = () => {
	const apiUrl = process.env.REACT_APP_API_URL;
	const [orders, setOrders] = useState([]);
	const [error, setError] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		const fetchOrders = async () => {
			try {
				const response = await fetch(`${apiUrl}/pdfs/all-pdfs`);
				const data = await response.json();
				console.log('Fetched Orders:', data);
				if (Array.isArray(data)) {
					setOrders(data);
				} else {
					console.error('Fetched data is not an array:', data);
					setError(data.error);
				}
			} catch (error) {
				console.error('Error fetching orders:', error);
				setError('An error occurred while fetching orders.');
			}
		};

		fetchOrders();
	}, [apiUrl]);

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleDownloadAttachment = async (pdfId) => {
		try {
			const response = await fetch(
				`${apiUrl}/form/download-attachments/${pdfId}`
			);

			if (response.ok) {
				const blob = await response.blob();
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `attachments_${pdfId}.zip`);
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			} else {
				console.error(
					'Failed to download attachments:',
					response.statusText
				);
				setError('Failed to download attachments.');
			}
		} catch (error) {
			console.error('Error downloading attachments:', error);
			setError('Failed to download attachments.');
		}
	};

	const sortedOrders = orders.sort(
		(a, b) => new Date(b.created_at) - new Date(a.created_at)
	);

	const filteredOrders = sortedOrders.filter((order) => {
		const fullName = `${order.first_name} ${order.last_name}`.toLowerCase();
		const pdfName = order.pdf_name.toLowerCase();
		const searchTermLower = searchTerm.toLowerCase();
		return (
			fullName.includes(searchTermLower) ||
			pdfName.includes(searchTermLower)
		);
	});

	return (
		<div className='orderData'>
			<h1>Alle ordre sendt inn</h1>
			{error && <p className='error'>{error}</p>}
			<input
				type='text'
				placeholder='Søk etter navn eller PDF...'
				value={searchTerm}
				onChange={handleSearchChange}
				className='searchInput'
			/>
			<div className='ordersList'>
				{Array.isArray(filteredOrders) && filteredOrders.length > 0 ? (
					<table>
						<thead>
							<tr>
								<th>Navn</th>
								<th>PDF</th>
								<th>Opprettet</th>
								<th>Attachments</th>
							</tr>
						</thead>
						<tbody>
							{filteredOrders.map((order) => (
								<tr key={order.id}>
									<td>{`${order.first_name} ${order.last_name}`}</td>
									<td>
										<div className='row'>
											<FaRegFilePdf size='20' />
											<a
												href={`data:application/pdf;base64,${order.file_data}`}
												download={order.pdf_name}
												target='_blank'
												rel='noopener noreferrer'
											>
												{order.pdf_name}
											</a>
										</div>
									</td>
									<td>
										{new Date(
											order.created_at
										).toLocaleString()}
									</td>
									<td>
										{order.has_attachments && (
											<button
												onClick={() =>
													handleDownloadAttachment(
														order.id // Pass the PDF id here
													)
												}
											>
												Last ned vedlegg
											</button>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<p>Ingen bestillinger funnet.</p>
				)}
			</div>
		</div>
	);
};

export default Orders;
