import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const LogoutButton = () => {
	const buttonStyle = {
		color: '#FFF',
		fontSize: '1.5rem',
	};

	const navigate = useNavigate();
	const { logout } = useAuth();

	const handleLogout = () => {
		logout(); // Call logout to update AuthContext state
		navigate('/login');
	};

	return (
		<button style={buttonStyle} onClick={handleLogout}>
			Logg ut
		</button>
	);
};

export default LogoutButton;
