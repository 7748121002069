import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminMenu from './AdminMenu';

// Components
import RegisterForm from '../../subsections/RegisterForm';
import Dashboard from './Dashboard';
import Errors from './Errors'; // Import the Errors component
import Users from './Users';

// SCSS
import '../../../assets/scss/Admin/AdminPanel.scss';

const AdminPanel = () => {
	const navigate = useNavigate();
	const [activeContent, setActiveContent] = useState('dashboard'); // default to 'dashboard'

	const contentComponents = {
		dashboard: <Dashboard />,
		users: <Users />,
		reguser: <RegisterForm />,
		errors: <Errors />, // Add Errors component to the contentComponents
	};

	const handleMenuClick = (contentKey) => {
		if (contentKey === 'skjema') {
			navigate('/');
		} else {
			setActiveContent(contentKey);
		}
	};

	return (
		<div className='adminPanel'>
			<AdminMenu onMenuItemClick={handleMenuClick} />
			<div className='adminPanel__content'>
				{contentComponents[activeContent]}
			</div>
		</div>
	);
};

export default AdminPanel;
