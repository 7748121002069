import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasRole } from '../../utils/auth';

// SCSS
import '../../assets/scss/registerForm.scss';

// Image
import adrBygg from '../../assets/images/adr_bygg.jpg';

const RegisterForm = () => {
	const navigate = useNavigate();
	const [registered, setRegistered] = useState(false);
	const [loading, setLoading] = useState(false);

	const [formData, setFormData] = useState({
		email: '',
		first_name: '',
		last_name: '',
		role: 'user',
		department: 'Oslo',
	});

	const apiUrl = process.env.REACT_APP_API_URL;

	useEffect(() => {
		// Redirect the user if they do not have the 'admin' or 'superAdmin' role
		if (!hasRole(['admin', 'superAdmin'])) {
			navigate('/404');
		}
	}, [navigate]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault(); // Prevent default form submission
		setLoading(true); // Start loading
		console.log(formData);
		try {
			const response = await fetch(`${apiUrl}/auth/register`, {
				// Adjust the URL/port as per your backend setup
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: formData.email,
					first_name: formData.first_name,
					last_name: formData.last_name,
					role: formData.role,
					department: formData.department,
				}),
			});

			const data = await response.json();
			if (response.ok) {
				// Handle success - maybe redirect to login or a page to check email
				setRegistered(true);
				console.log('Registration successful:', data);

				// Reset the form after 3 seconds
				setTimeout(() => {
					setFormData({
						email: '',
						first_name: '',
						last_name: '',
						role: 'user',
						department: 'Oslo',
					});
					setRegistered(false);
					setLoading(false); // Stop loading
				}, 3000);
			} else {
				// Handle errors - show error message to user
				alert(data.message || 'An error occurred during registration.');
				setLoading(false); // Stop loading
			}
		} catch (error) {
			console.error('Registration error:', error);
			setLoading(false); // Stop loading
		}
	};

	// Set the Object arrays for roles and departments
	const roles = [
		{ value: 'user', label: 'Bruker' },
		{ value: 'admin', label: 'Administrator' },
	];

	const departments = [
		{ value: 'Oslo', label: 'Oslo' },
		{ value: 'Hamar', label: 'Hamar' },
		{ value: 'Trondheim', label: 'Trondheim' },
		{ value: 'Molde', label: 'Molde' },
		{ value: 'Ålesund', label: 'Ålesund' },
		{ value: 'Stavanger', label: 'Stavanger' },
	];

	return (
		<div className='regOuter'>
			<div className='registerForm'>
				<h2>Registrer bruker</h2>
				<div className='registerContainer'>
					<form onSubmit={handleSubmit}>
						<div>
							<label htmlFor='email'>Epost</label>
							<input
								type='email'
								id='email'
								name='email'
								value={formData.email}
								placeholder='ola.normann@norge.no'
								onChange={handleChange}
							/>
						</div>
						<div className='regFirstLast'>
							<div>
								<label htmlFor='firstName'>Fornavn</label>
								<input
									type='text'
									id='firstName'
									name='first_name'
									value={formData.first_name}
									placeholder='Ola'
									onChange={handleChange}
								/>
							</div>
							<div>
								<label htmlFor='lastName'>Etternavn</label>
								<input
									type='text'
									id='lastName'
									name='last_name'
									value={formData.last_name}
									placeholder='Normann'
									onChange={handleChange}
								/>
							</div>
						</div>
						<div>
							<label htmlFor='regRoleSelect'>Velg Rolle</label>
							<select
								name='role'
								id='regRoleSelect'
								value={formData.role}
								onChange={handleChange}
							>
								{roles.map((role) => (
									<option key={role.value} value={role.value}>
										{role.label}
									</option>
								))}
							</select>
						</div>
						<div>
							<label htmlFor='regDepSelect'>Velg Avdeling</label>
							<select
								name='department'
								id='regDepSelect'
								value={formData.department}
								onChange={handleChange}
							>
								{departments.map((department) => (
									<option
										key={department.value}
										value={department.value}
									>
										{department.label}
									</option>
								))}
							</select>
						</div>

						<button type='submit' disabled={loading}>
							{loading ? 'Registrerer...' : 'Registrer bruker'}
						</button>
					</form>
					{registered && <p>Bruker {formData.email} er registrert</p>}
				</div>
			</div>
		</div>
	);
};

export default RegisterForm;
