import React from 'react';

const AdditionalServices = ({
	services,
	register,
	selectedPackage,
	selectedServices,
	setSelectedServices,
	getValues,
}) => {
	if (!services || !services.length) return null;

	const getCheckedSubPackagesArr = (
		checkedEntries,
		serviceSubPackageList
	) => {
		return checkedEntries
			?.map(([id]) => {
				return serviceSubPackageList
					? serviceSubPackageList.find((el) => el.id === id)
					: '';
			})
			.filter((el) => el);
	};

	const handlePackageCheckboxChange = (serviceName, subPackageArr = []) => {
		// Get the current state of isChecked for the package
		const currentIsChecked =
			selectedServices[serviceName]?.isChecked || false;

		// If there are subPackages, handle them
		const formSubPackages = Object.entries(
			getValues(`additionalServices.${serviceName}.subPackages`) || {}
		);
		const checkedSubPackagesEntries = formSubPackages.filter(
			([, checked]) => !!checked
		);
		const selectedSubPackages = getCheckedSubPackagesArr(
			checkedSubPackagesEntries,
			subPackageArr
		);
		const formattedSelectedSubPackages = selectedSubPackages.reduce(
			(acc, item) => {
				acc[item.id] = { ...item, isChecked: item.isChecked };
				return acc;
			},
			{}
		);

		// Update the state to toggle the isChecked status of the package
		setSelectedServices((prev) => {
			const updatedSubPackages = {};

			// If the current state is checked (and we are about to uncheck), clear all sub-packages' isChecked
			if (currentIsChecked) {
				subPackageArr.forEach((subPackage) => {
					if (prev[serviceName]?.subPackages[subPackage.id]) {
						updatedSubPackages[subPackage.id] = {
							...prev[serviceName].subPackages[subPackage.id],
							isChecked: false,
						};
					}
				});
			} else {
				// Otherwise, retain the existing sub-package states (or you could set them all to checked if that's desired)
				subPackageArr.forEach((subPackage) => {
					if (prev[serviceName]?.subPackages[subPackage.id]) {
						updatedSubPackages[subPackage.id] = {
							...prev[serviceName].subPackages[subPackage.id],
							isChecked: true,
						};
					}
				});
			}

			return {
				...prev,
				[serviceName]: {
					...prev[serviceName],
					isChecked: !currentIsChecked,
					subPackages: updatedSubPackages,
				},
			};
		});
	};

	const handleSubPackageCheckboxChange = (
		serviceName,
		subPackageId = null,
		subServices
	) => {
		const SubPackagesList = Object.entries(
			getValues(`additionalServices.${serviceName}.subPackages`) || {}
		);
		const checkedSubPackagesEntries = SubPackagesList?.filter(
			([, checked]) => !!checked
		);

		const selectedPackage =
			subServices.find((el) => el.id === subPackageId) || {};
		if (Object.entries(selectedPackage).length === 0) {
			console.error('Something wrong with data');
			return;
		}
		setSelectedServices((prev) => {
			const prevSelectedArr = Object.values(
				prev[serviceName].subPackages || {}
			);
			const newSelextedSubPackageArr = getCheckedSubPackagesArr(
				checkedSubPackagesEntries,
				[...prevSelectedArr, selectedPackage]
			);
			const formatedSelectedSupPackages = newSelextedSubPackageArr.reduce(
				(acc, item) => {
					acc[item.id] = item;
					return acc;
				},
				{}
			);

			return {
				...prev,
				[serviceName]: {
					...prev[serviceName],
					isChecked: true,
					subPackages: formatedSelectedSupPackages,
				},
			};
		});
	};

	const renderServiceDetails = (service) => (
		<>
			{service.description && (
				<p className='text-gray-500 text-sm'>{service.description}</p>
			)}
			{service.kroner && (
				<p className='text-gray-700 text-sm'>{`Pris: ${service.kroner},-`}</p>
			)}
		</>
	);

	return (
		<div className='mt-12 bg-white shadow-lg rounded-lg addPackages'>
			<h3 className='text-xl font-semibold text-gray-900 mb-4'>
				Tilvalg
			</h3>
			<div className='grid grid-cols-2 gap-4'>
				{services.map((service) => (
					<div key={service.name} className='flex flex-col mb-4'>
						<div className='flex items-center'>
							<input
								id={service.name}
								name={service.name}
								type='checkbox'
								checked={
									selectedServices[service.name]?.isChecked ||
									false
								}
								onChange={() => {
									handlePackageCheckboxChange(
										service.name,
										service.subPackages,
										service
									);
								}}
								ref={register(
									`additionalServices.${service.name}`
								)}
								className='focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2'
							/>
							<label
								htmlFor={service.name}
								className='font-medium text-gray-700'
							>
								{service.name}
							</label>
						</div>
						{renderServiceDetails(service)}
						{service.subPackages &&
							selectedServices[service.name]?.isChecked && (
								<div className='ml-4 mt-2 sub-packages'>
									{service.subPackages.map((sub) => (
										<div key={sub.name} className='mb-2'>
											<input
												id={sub.name}
												name={`additionalServices.${service.name}.subPackages.${sub.id}`}
												type='checkbox'
												{...register(
													`additionalServices.${service.name}.subPackages.${sub.id}`,
													{
														onChange: () => {
															handleSubPackageCheckboxChange(
																service.name,
																sub.id,
																service.subPackages
															);
														},
													}
												)}
												className='focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2'
											/>
											<label
												htmlFor={sub.name}
												className='text-gray-700'
											>
												{sub.name}
												{renderServiceDetails(sub)}
											</label>
										</div>
									))}
								</div>
							)}
					</div>
				))}
			</div>
		</div>
	);
};

export default AdditionalServices;
