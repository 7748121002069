import React, { useEffect } from 'react';
import {
	Route,
	BrowserRouter as Router,
	Routes,
	useLocation,
} from 'react-router-dom';
import Header from './components/Header';
import PrivateRoute from './components/privateRoute';
import SalesForm from './sections/Form';
import AdminPanel from './sections/pageSections/Admin/AdminPanel';
import Orders from './sections/pageSections/Orders';
import Profile from './sections/pageSections/Profile';
import Review from './sections/pageSections/Review';
import RegisterForm from './sections/subsections/RegisterForm';
import SetPasswordForm from './sections/subsections/SetPasswordForm';
import LoginForm from './sections/subsections/loginForm';

import { AuthProvider } from './contexts/AuthContext';

import './assets/scss/main.scss';

const AppContent = () => {
	const location = useLocation();

	const showHeader = !location.pathname.startsWith('/admin');

	return (
		<div className='App'>
			{showHeader && (
				<header className='App-header'>
					<Header />
				</header>
			)}
			<div className={showHeader ? 'mainContent' : 'adminContent'}>
				<Routes>
					<Route
						path='/'
						element={
							<PrivateRoute>
								<SalesForm />
							</PrivateRoute>
						}
					/>
					<Route path='/login' element={<LoginForm />} />
					<Route path='/reguser' element={<RegisterForm />} />
					<Route path='/set-password' element={<SetPasswordForm />} />
					<Route
						path='/admin/*'
						element={
							<PrivateRoute>
								<AdminPanel />
							</PrivateRoute>
						}
					/>
					<Route
						path='/review'
						element={
							<PrivateRoute>
								<Review />
							</PrivateRoute>
						}
					/>
					<Route
						path='/orders'
						element={
							<PrivateRoute>
								<Orders />
							</PrivateRoute>
						}
					/>
					<Route
						path='/profile'
						element={
							<PrivateRoute>
								<Profile />
							</PrivateRoute>
						}
					/>
					{/* Add this route for recovering saved forms */}
					<Route path='/form/recover/:id' element={<SalesForm />} />
				</Routes>
			</div>
		</div>
	);
};

function App() {
	return (
		<AuthProvider>
			<Router>
				<AppContent />
			</Router>
		</AuthProvider>
	);
}

export default App;
