import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../../../assets/scss/Admin/Errors.scss';

const Errors = () => {
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		const fetchErrors = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/errors/fetch-errors`
				);
				setErrors(response.data);
			} catch (error) {
				console.error('Error fetching errors:', error);
			}
		};

		fetchErrors();
	}, []);

	return (
		<div className='errorsPage'>
			<h1>Frontend Feilmeldinger</h1>
			<table>
				<thead>
					<tr>
						<th>ID</th>
						<th>Message</th>
						<th>Source</th>
						<th>Line</th>
						<th>Column</th>
						<th>User Agent</th>
						<th>Created At</th>
					</tr>
				</thead>
				<tbody>
					{errors.map((error) => (
						<tr key={error.id}>
							<td>{error.id}</td>
							<td>{error.message}</td>
							<td>{error.source}</td>
							<td>{error.lineno}</td>
							<td>{error.colno}</td>
							<td>{error.user_agent}</td>
							<td>
								{new Date(error.created_at).toLocaleString()}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default Errors;
