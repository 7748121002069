export const additionalServicesBasedOnLocation = {
	Trondheim: {
		Mediumliten: [],
		Mediumstor: [],
		Premium: [
			{
				id: 'pakkePremium2Trondheim',
				name: 'Content premium, 100 000 visninger',
				description: 'Fast rabatt 60%',
			},
			{
				id: 'pakkePremium3Trondheim',
				name: 'Content, 100 000 visninger',
				description: 'Fast rabatt 60%',
			},
			{
				id: 'pakkePremium4Trondheim',
				name: 'Native, 100 000 visninger',
				description: 'Fast rabatt 60%',
			},
			{
				id: 'pakkePremium8Trondheim',
				name: 'Jobbkarusell',
				description: 'Per ekstra annonse',
			},
		],
	},
	Molde: {
		Mediumstor: [],
		Premium: [
			{
				id: 'pakkePremium1Molde',
				name: 'Print helside annonse Sunnmørsposten',
			},
			{
				id: 'pakkePremium2Molde',
				name: 'Print helside annonse Romsdal Budstikke',
			},
			{
				id: 'pakkePremium3Molde',
				name: 'Print dobbeltside annonse Sunnmørsposten',
			},
			{
				id: 'pakkePremium4Molde',
				name: 'Print dobbeltside annonse Romsdal Budstikke',
			},
			{
				id: 'pakkePremium5Molde',
				name: 'Produksjon av print',
			},
			{
				id: 'pakkePremium6Molde',
				name: 'Content premium, 100 000 visninger',
				description: 'Fast rabatt 60%',
			},
			{
				id: 'pakkePremium7Molde',
				name: 'Content, 100 000 visninger',
				description: 'Fast rabatt 60%',
			},
			{
				id: 'pakkePremium8Molde',
				name: 'Native, 100 000 visninger',
				description: 'Fast rabatt 60%',
			},
			{
				id: 'pakkePremium9Molde',
				name: 'Jobbkarusell, 16 nettaviser i PM Nordvestlandet',
			},
		],
	},
	Ålesund: {
		Mediumstor: [],
		Premium: [
			{
				id: 'pakkePremium1Alesund',
				name: 'Print helside annonse Sunnmørsposten',
			},
			{
				id: 'pakkePremium2Alesund',
				name: 'Print helside annonse Romsdal Budstikke',
			},
			{
				id: 'pakkePremium3Alesund',
				name: 'Print dobbeltside annonse Sunnmørsposten',
			},
			{
				id: 'pakkePremium4Alesund',
				name: 'Print dobbeltside annonse Romsdal Budstikke',
			},
			{
				id: 'pakkePremium5Alesund',
				name: 'Produksjon av print',
			},
			{
				id: 'pakkePremium6Alesund',
				name: 'Content premium, 100 000 visninger',
				description: 'Fast rabatt 60%',
			},
			{
				id: 'pakkePremium7Alesund',
				name: 'Content, 100 000 visninger',
				description: 'Fast rabatt 60%',
			},
			{
				id: 'pakkePremium8Alesund',
				name: 'Native, 100 000 visninger',
				description: 'Fast rabatt 60%',
			},
			{
				id: 'pakkePremium9Alesund',
				name: 'Jobbkarusell, 16 nettaviser i PM Nordvestlandet',
			},
		],
	},
	Hamar: {
		Mediumliten: [
			{
				id: 'pakkeliten1Hamar',
				name: 'Jobbkarusell Amedia',
				description:
					'Hamar Arbeiderblad, Østlendingen, Ringsaker blad, Oppland Arbeiderblad. Bestill hos: ole.bodogaard@amedia.no. Avtale: 2 ukers liggetid på forside.',
			},
		],
		Mediumstor: [
			{
				id: 'pakkestor1Hamar',
				name: 'Jobbkarusell Amedia',
				description:
					'Hamar Arbeiderblad, Østlendingen, Ringsaker blad, Oppland Arbeiderblad. Bestill hos: ole.bodogaard@amedia.no. Avtale: 2 ukers liggetid på forside.',
			},
		],
		Premium: [],
	},
	Oslo: {},
	Stavanger: {},
};

export const commonPackages = {
	Mediumliten: [],
	Mediumstor: [
		{
			id: 'Jobbkarusell',
			name: 'Jobbkarusell',
			description: 'Inneholder undervalg',
			subPackages: [
				{
					id: 'trondelag',
					name: 'Trøndelag',
					description: 'Polaris Media, Trøndelag, 16 nettaviser',
					kroner: 4400,
				},
				{
					id: 'mr',
					name: 'Møre og Romsdal',
					description:
						'Polaris Media, Møre og Romsdal, 16 nettaviser',
					kroner: 4400,
				},
				{
					id: 'nn',
					name: 'Nord Norge',
					description: 'Polaris Media, Nord Norge, 8 nettaviser',
					kroner: 3400,
				},
				{
					id: 'sn',
					name: 'Sør Norge',
					description: 'Polaris Media, Sør Norge, 8 nettaviser',
					kroner: 3400,
				},
				{
					id: 'vn',
					name: 'Vest Norge',
					description: 'Polaris Media, Vest Norge, 9 nettaviser',
					kroner: 3400,
				},
				{
					id: 'amedia',
					name: 'Amedia',
					description: 'Amedia, 4 valgfrie nettaviser',
					kroner: 4000,
				},
			],
		},
		{
			id: 'spendingSOME',
			name: 'Ekstra spending',
			description: 'Inneholder undervalg',
			subPackages: [
				{
					id: 'faceInsta',
					name: 'Facebook / Instagram',
					description: 'Facebook og Instagram',
					kroner: 5000,
				},
				{
					id: 'linkedin',
					name: 'LinkedIn',
					description: 'LinkedIn',
					kroner: 2000,
				},
				{
					id: 'snapchat',
					name: 'Snapchat',
					description: 'Snapchat (Oppsett 1280,-, Spending 5000,-',
					kroner: 6280,
				},
				{
					id: 'youtube',
					name: 'YouTube',
					description: 'YouTube (Oppsett 1280,-, Spending 5000,-',
					kroner: 6280,
				},
			],
		},
	],
	Premium: [
		{
			id: 'Videopremium',
			name: 'Video',
			description: 'I innholdsannonse / artikkel',
			kroner: 10000,
		},
		{
			id: 'snapchatPremium',
			name: 'Snapchat',
			description: 'Snapchat (Oppsett 1280,-, Spending 5000,-',
			kroner: 6280,
		},
		{
			id: 'youtubePremium',
			name: 'YouTube',
			description: 'YouTube (Oppsett 1280,-, Spending 5000,-',
			kroner: 6280,
		},
		{
			id: 'spendingPremiumSOME',
			name: 'Ekstra spending',
			description: 'Inneholder undervalg',
			subPackages: [
				{
					id: 'faceInstaPremium',
					name: 'Facebook / Instagram',
					description: 'Facebook og Instagram',
					kroner: 8000,
				},
				{
					id: 'linkedinPremium',
					name: 'LinkedIn',
					description: 'LinkedIn',
					kroner: 4000,
				},
			],
		},
		{
			id: 'spendingPremiumBanner',
			name: 'Ekstra spending bannerannonsering',
			description:
				'Ekstra spending bannerannonsering/distribusjon av innholdsannonse (Fast rabatt 55-60%)',
			kroner: 15000,
		},
	],
};
