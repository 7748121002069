import '../assets/scss/inputComponent.scss';

const InputComponent = ({
	label,
	type,
	name,
	className,
	placeholder,
	readOnly,
	required,
	register,
	onKeyDown,
	...rest
}) => {
	return (
		<div className={`input-container ${className}`}>
			<label htmlFor={name}>
				{label}
				{required && (
					<span style={{ color: 'red' }} className='required'>
						*
					</span>
				)}
			</label>
			<input
				type={type}
				name={name}
				id={name}
				placeholder={placeholder}
				readOnly={readOnly}
				required={required}
				onKeyDown={onKeyDown}
				{...register(name, { required })}
				{...rest}
			/>
		</div>
	);
};

export default InputComponent;
