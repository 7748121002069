import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import '../../assets/scss/setPassword.scss';

const SetPasswordForm = () => {
	const [searchParams] = useSearchParams();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const token = searchParams.get('token'); // Assuming the token is passed as a URL query parameter
	const [passwordSet, setPasswordSet] = useState(false);

	const apiUrl = process.env.REACT_APP_API_URL;

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			alert('Passordene er ikke like. Prøv igjen.');
			return;
		}

		try {
			const response = await fetch(`${apiUrl}/auth/set-password`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ token, password }),
			});

			const data = await response.json();
			if (response.ok) {
				console.log('Password set successfully:', data);
				setPasswordSet(true);
			} else {
				alert(
					data.message ||
						'An error occurred while setting the password.'
				);
			}
		} catch (error) {
			console.error('Error setting password:', error);
		}
	};

	return (
		<div className='setPasswordFormContainer'>
			<div className='setPasswordForm'>
				<h2>Sett ditt passord</h2>
				<form onSubmit={handleSubmit}>
					<div>
						<label htmlFor='password'>Passord</label>
						<input
							type='password'
							id='password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div>
						<label htmlFor='confirmPassword'>Bekreft passord</label>
						<input
							type='password'
							id='confirmPassword'
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
					</div>
					<input type='submit' value='Sett passord' />
				</form>
				{passwordSet && (
					<>
						<p>Passordet ditt er nå endret!</p> <br />{' '}
						<a href='/login'>Til innlogging</a>
					</>
				)}
			</div>
		</div>
	);
};

export default SetPasswordForm;
