import { FaExclamationCircle } from 'react-icons/fa';
import InputComponent from '../../components/InputComponent';
import TextareaComponent from '../../components/TextareaComponent';

// SCSS
import '../../assets/scss/sectionThree.scss';

const SectionThree = ({ register, formData, handleChange }) => {
	return (
		<div className='sectionThree flex-col'>
			<div className='flex-row'>
				<div className='flex-col landSok'>
					<div className='landingpage'>
						<InputComponent
							label={
								<span>
									Link til landingsside{' '}
									<b>(Recman / Hubspot)</b>
								</span>
							}
							type='url'
							name='landingpage'
							placeholder='https://www.landingsside.no'
							register={register}
							onChange={(e) =>
								handleChange(e.target.name, e.target.value)
							}
						/>
					</div>

					<div className='soknadsfrist'>
						<InputComponent
							label='Søknadsfrist'
							type='date'
							name='soknadsfrist'
							register={register}
							onChange={(e) =>
								handleChange(e.target.name, e.target.value)
							}
						/>
					</div>
				</div>
				<div className='feed flex-row'>
					<TextareaComponent
						label='Feed-tekst til innlegg'
						name='feed-text'
						className='feed-text-innlegg'
						placeholder='Skriv inn tekst til feed til innlegg'
						cols='40'
						rows='4'
						maxLength='120'
						register={register}
						counter={false}
					/>
					<small className='field-description-text'>
						- FB viser kun de 3 øverste linjene før man må trykke
						"se mer" i selve teksten. Det er plass til ca. 120 tegn
						på de tre linjene (inkl. mellomrom) Det viktigste
						budskapet bør derfor komme frem først. <br />
						<br />- Bruk "Kundenavn søker", <u>IKKE</u> "vi søker".{' '}
						<br />
						<br />- Skriv med et direkte språk til brukeren "du/deg
						ikke vi/vårt".
						<FaExclamationCircle size='28' />
					</small>
				</div>
			</div>
		</div>
	);
};

export default SectionThree;
