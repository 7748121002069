import { Controller } from 'react-hook-form';
import '../../assets/scss/sectionFive.scss';
import FileUploadComponent from '../../components/FileUpload';

const SectionFive = ({ setValue, register, formData, control }) => {
	return (
		<div className='sectionFive'>
			<div className='fileUpload mt-24 mb-24'>
				<h2 className='headline'>Annonsemateriell</h2>
				<div className='border'></div>
				<small className='field-description-text mb-6'>
					Ønsker kunde å bruke egen fargepalett? Legg i så fall ved
					profilhåndbok. <br /> Hvis ikke setter designer opp eget
					fargeforslag basert på farger i deres logo og bilde. <br />
					<br />
					<b>
						Last opp bilde/video, logo, evt. profilhåndbok (Maks 80
						MB)
					</b>
				</small>
				<Controller
					control={control}
					name='companyProfile'
					render={({ field }) => (
						<FileUploadComponent
							label='Last opp'
							{...field}
							setValue={setValue}
							register={register}
						/>
					)}
				/>
			</div>
		</div>
	);
};

export default SectionFive;
