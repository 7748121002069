import { jwtDecode } from 'jwt-decode';

export const getUserRole = () => {
	const token = localStorage.getItem('token');
	if (!token) return null;

	try {
		// Use the correct named export for decoding
		const decoded = jwtDecode(token);
		return decoded.role;
	} catch (error) {
		console.error('Error decoding token:', error);
		return null;
	}
};

export const hasRole = (requiredRoles) => {
	const userRole = getUserRole();
	return requiredRoles.includes(userRole);
};

export const isLoggedIn = () => {
	return !!localStorage.getItem('token');
};

export const getUserId = () => {
	const token = localStorage.getItem('token');
	if (!token) return null;

	try {
		const decoded = jwtDecode(token);
		return decoded.id;
	} catch (error) {
		console.error('Error decoding token:', error);
		return null;
	}
};
