import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CiTrash } from 'react-icons/ci';
import { FaPencilAlt } from 'react-icons/fa';
import '../../../assets/scss/Admin/Users.scss';
import { useAuth } from '../../../contexts/AuthContext';
import ConfirmationModal from './confirmModal';

const Users = () => {
	const { userRole } = useAuth();
	const [users, setUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [editingRoleId, setEditingRoleId] = useState(null);
	const [selectedRole, setSelectedRole] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [userToEdit, setUserToEdit] = useState(null);

	// Changing Email
	const [isEmailChangeModalOpen, setIsEmailChangeModalOpen] = useState(false);
	const [newEmail, setNewEmail] = useState('');
	const [confirmNewEmail, setConfirmNewEmail] = useState('');
	const [emailUpdateError, setEmailUpdateError] = useState('');

	// Password changing
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [passwordUpdateError, setPasswordUpdateError] = useState('');
	const [currentAction, setCurrentAction] = useState('');

	// Role change
	const [isRoleChanged, setIsRoleChanged] = useState(false);

	// Message
	const [message, setMessage] = useState('');

	const apiUrl = process.env.REACT_APP_API_URL;

	// Delete user
	const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
		useState(false);
	const [deleteError, setDeleteError] = useState('');

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await axios.get(`${apiUrl}/auth/users`);
				setUsers(response.data);
				setFilteredUsers(response.data);
			} catch (error) {
				console.error('Error fetching users:', error);
			}
		};
		fetchUsers();
	}, [apiUrl]);

	useEffect(() => {
		if (isRoleChanged) {
			setIsModalOpen(true);
		}
	}, [isRoleChanged]);

	useEffect(() => {
		const results = users.filter(
			(user) =>
				user.first_name
					.toLowerCase()
					.includes(searchTerm.toLowerCase()) ||
				user.last_name
					.toLowerCase()
					.includes(searchTerm.toLowerCase()) ||
				user.email.toLowerCase().includes(searchTerm.toLowerCase())
		);
		setFilteredUsers(results);
	}, [searchTerm, users]);

	// Handle search input change
	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	// Handle select all users
	const handleSelectAll = (e) => {
		if (e.target.checked) {
			const allUserIds = filteredUsers.map((user) => user.id);
			setSelectedUsers(allUserIds);
		} else {
			setSelectedUsers([]);
		}
	};

	// Handle select individual user
	const handleSelectUser = (userId) => {
		if (selectedUsers.includes(userId)) {
			setSelectedUsers(selectedUsers.filter((id) => id !== userId));
		} else {
			setSelectedUsers([...selectedUsers, userId]);
		}
	};

	// Handle batch actions
	const handleBatchAction = async (action) => {
		if (action === 'delete') {
			setIsDeleteConfirmationModalOpen(true);
		} else if (action === 'setPassword') {
			setIsModalOpen(true);
			setCurrentAction('setPassword');
		} else if (action === 'forcePasswordChange') {
			await handleForcePasswordChange(selectedUsers);
		}
	};

	const handleForcePasswordChange = async (userIds) => {
		try {
			await axios.patch(`${apiUrl}/auth/force-password-change`, {
				userIds,
			});
			setMessage(`Password change required for ${userIds.length} users`);
		} catch (error) {
			console.error('Error forcing password change:', error);
			setMessage('Failed to force password change');
		}
	};

	const handleDeleteConfirm = async () => {
		try {
			await Promise.all(
				selectedUsers.map((userId) =>
					axios.delete(`${apiUrl}/auth/users/${userId}`)
				)
			);
			setUsers(users.filter((user) => !selectedUsers.includes(user.id)));
			setSelectedUsers([]);
			setIsDeleteConfirmationModalOpen(false);
			setMessage(`Slettet ${selectedUsers.length} brukere`);
		} catch (error) {
			console.error('Error deleting users:', error);
			setDeleteError('Failed to delete the users.');
		}
	};

	const handlePasswordUpdateConfirm = async () => {
		if (newPassword !== confirmNewPassword) {
			setPasswordUpdateError('Passordene må være like.');
			return;
		}

		try {
			const response = await axios.patch(
				`${apiUrl}/auth/users/passwords`,
				{
					userIds: selectedUsers.map((id) => parseInt(id, 10)),
					newPassword,
				}
			);
			setIsModalOpen(false);
			setNewPassword('');
			setConfirmNewPassword('');
			setPasswordUpdateError('');
			setMessage(`Oppdatert passord for ${selectedUsers.length} brukere`);
		} catch (error) {
			console.error('Error updating passwords:', error);
			setPasswordUpdateError(
				'En feil oppstod ved oppdatering av passord.'
			);
		}
	};

	// Handle single user email change confirmation
	const handleEmailChangeConfirm = async () => {
		if (newEmail !== confirmNewEmail) {
			alert('Epostene må være like. Prøv igjen!');
			return;
		}
		if (!newEmail.trim()) {
			alert('Vennligst skriv inn en gyldig epost adresse');
			return;
		}

		try {
			const response = await axios.patch(
				`${apiUrl}/auth/users/${userToEdit.id}/email`,
				{ email: newEmail }
			);
			setUsers(
				users.map((user) =>
					user.id === userToEdit.id
						? { ...user, email: newEmail }
						: user
				)
			);
			setIsEmailChangeModalOpen(false);
			setUserToEdit(null);
			setNewEmail('');
			setConfirmNewEmail('');
			setEmailUpdateError('');
			setMessage(
				`Du har endret eposten til ${userToEdit.first_name} ${userToEdit.last_name} til ${newEmail}`
			);
		} catch (error) {
			console.error('Error updating email:', error);
			const errorMessage =
				error.response?.data?.message ||
				'En feil oppstod ved oppdatering av e-post.';
			setEmailUpdateError(errorMessage);
		}
	};

	// Existing delete user logic
	const handleDeleteClick = (user) => {
		if (userRole === 'admin' && user.role === 'superAdmin') {
			alert(
				'Du har ikke tilstrekkelige tilganger til å slette denne brukeren.'
			);
			return;
		}
		setUserToEdit(user);
		setIsDeleteConfirmationModalOpen(true);
	};

	const handleDeleteConfirmSingle = async () => {
		console.log('Deleting user:', userToEdit);
		try {
			const response = await axios.delete(
				`${apiUrl}/auth/users/${userToEdit.id}`
			);
			console.log('Delete response:', response);
			setUsers(users.filter((user) => user.id !== userToEdit.id));
			setIsDeleteConfirmationModalOpen(false);
			setUserToEdit(null);
			setMessage(`Brukeren ${userToEdit.username} er slettet`);
		} catch (error) {
			console.error('Error deleting user:', error);
			setDeleteError('Failed to delete the user.');
		}
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setIsEmailChangeModalOpen(false);
		setIsDeleteConfirmationModalOpen(false);
		setCurrentAction('');
	};

	const handleSelectChange = (e, user) => {
		const selectedAction = e.target.value;
		setUserToEdit(user);
		setCurrentAction(selectedAction);

		if (selectedAction === 'editEmail') {
			setIsEmailChangeModalOpen(true);
			setIsModalOpen(false);
		} else if (selectedAction === 'setPassword') {
			setNewPassword('');
			setConfirmNewPassword('');
			setPasswordUpdateError('');
			setIsEmailChangeModalOpen(false);
			setIsModalOpen(true);
		} else if (selectedAction === 'forcePasswordChange') {
			handleForcePasswordChange([user.id]);
		} else if (selectedAction === 'changeRole') {
			if (userRole === 'admin' && user.role === 'superAdmin') {
				alert(
					'Du har ikke tilstrekkelige tilganger til å endre rollen til denne brukeren.'
				);
				return;
			}
			setSelectedRole(user.role);
			setEditingRoleId(user.id);
			setIsModalOpen(true);
		}
	};

	const handleEditClick = (user) => {
		if (userRole === 'admin' && user.role === 'superAdmin') {
			alert(
				'Du har ikke tilstrekkelige tilganger til å endre rollen til denne brukeren.'
			);
			return;
		}
		setUserToEdit(user);
		setSelectedRole(user.role);
		setEditingRoleId(user.id);
		setCurrentAction('changeRole');
		setIsModalOpen(true);
	};

	const handleRoleChange = (e) => {
		setSelectedRole(e.target.value);
		if (e.target.value !== userToEdit?.role) {
			setIsModalOpen(true);
		}
	};

	const handleConfirmRoleChange = async () => {
		setIsModalOpen(false);
		if (userToEdit && selectedRole !== userToEdit.role) {
			if (userRole === 'admin' && selectedRole === 'superAdmin') {
				alert(
					'Du har ikke tilstrekkelige tilganger til å endre rollen til superAdmin.'
				);
				return;
			}
			try {
				const response = await axios.patch(
					`${apiUrl}/auth/users/${userToEdit.id}/role`,
					{ role: selectedRole }
				);
				setUsers(
					users.map((user) =>
						user.id === userToEdit.id
							? { ...user, role: selectedRole }
							: user
					)
				);
				setUserToEdit(null);
				setEditingRoleId(null);
				setMessage(
					`Du har endret rollen til ${userToEdit.first_name} ${userToEdit.last_name} til ${selectedRole}`
				);
			} catch (error) {
				console.error('Error updating role:', error);
			}
		}
	};

	return (
		<div className='userDashboard'>
			<h1>Registrerte brukere</h1>
			<div className='search'>
				<input
					type='text'
					placeholder='Søk brukere...'
					value={searchTerm}
					onChange={handleSearchChange}
				/>
			</div>
			<div className='userDashboard__content'>
				<h2 className='edit_msg'>{message}</h2>
				<div className='batchActions'>
					<select onChange={(e) => handleBatchAction(e.target.value)}>
						<option value=''>Velg handling</option>
						<option value='setPassword'>
							Sett passord på valgte
						</option>
						<option value='delete'>Slett valgte brukere</option>
						<option value='forcePasswordChange'>
							Tving passordbytte
						</option>
					</select>
				</div>
				<div className='users'>
					<table>
						<thead>
							<tr>
								<th>
									<input
										type='checkbox'
										onChange={handleSelectAll}
										checked={
											selectedUsers.length ===
											filteredUsers.length
										}
									/>
								</th>
								<th>Navn</th>
								<th>Epost adresse</th>
								<th>Rolle</th>
								<th>Handling</th>
								<th>Slett bruker</th>
							</tr>
						</thead>
						<tbody>
							{filteredUsers.map((user, index) => (
								<tr key={user.id}>
									<td>
										<input
											type='checkbox'
											checked={selectedUsers.includes(
												user.id
											)}
											onChange={() =>
												handleSelectUser(user.id)
											}
										/>
									</td>
									<td>
										{user.first_name} {user.last_name}
									</td>
									<td>{user.email}</td>
									<td>
										{editingRoleId === user.id ? (
											<>
												<button
													onClick={() =>
														handleEditClick(user)
													}
												>
													Change Role
												</button>
											</>
										) : (
											<>
												{user.role}
												<FaPencilAlt
													onClick={() =>
														handleEditClick(user)
													}
												/>
											</>
										)}
									</td>
									<td>
										<select
											onChange={(e) =>
												handleSelectChange(e, user)
											}
										>
											<option>Velg handling</option>
											<option value='setPassword'>
												Sett passord
											</option>
											<option value='editEmail'>
												Endre e-post
											</option>
											<option value='forcePasswordChange'>
												Tving passordbytte
											</option>
										</select>
									</td>
									<td>
										<CiTrash
											size='24'
											onClick={() =>
												handleDeleteClick(user)
											}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<ConfirmationModal
				isOpen={
					isModalOpen ||
					isEmailChangeModalOpen ||
					isDeleteConfirmationModalOpen
				}
				onClose={handleCloseModal}
				onConfirm={
					isEmailChangeModalOpen
						? handleEmailChangeConfirm
						: isDeleteConfirmationModalOpen
						? handleDeleteConfirmSingle
						: currentAction === 'setPassword'
						? handlePasswordUpdateConfirm
						: currentAction === 'changeRole'
						? handleConfirmRoleChange
						: null
				}
				content={
					isEmailChangeModalOpen ? (
						<>
							<label htmlFor='newEmail'>Ny e-post</label>
							<input
								id='newEmail'
								type='email'
								value={newEmail}
								placeholder='Ny e-post'
								onChange={(e) => setNewEmail(e.target.value)}
							/>
							<label htmlFor='confirmNewEmail'>
								Bekreft ny e-post
							</label>
							<input
								id='confirmNewEmail'
								type='email'
								value={confirmNewEmail}
								placeholder='Bekreft ny e-post'
								onChange={(e) =>
									setConfirmNewEmail(e.target.value)
								}
							/>
							{emailUpdateError && (
								<p style={{ color: 'red' }}>
									{emailUpdateError}
								</p>
							)}
						</>
					) : isDeleteConfirmationModalOpen ? (
						<p>
							Er du sikker på at du vil slette brukeren{' '}
							{userToEdit.first_name} {userToEdit.last_name}?
						</p>
					) : currentAction === 'setPassword' ? (
						<>
							<label htmlFor='newPassword'>Nytt passord</label>
							<input
								id='newPassword'
								type='password'
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								placeholder='Nytt passord'
							/>
							<label htmlFor='confirmNewPassword'>
								Bekreft nytt passord
							</label>
							<input
								id='confirmNewPassword'
								type='password'
								value={confirmNewPassword}
								onChange={(e) =>
									setConfirmNewPassword(e.target.value)
								}
								placeholder='Bekreft nytt passord'
							/>
							{passwordUpdateError && (
								<p style={{ color: 'red' }}>
									{passwordUpdateError}
								</p>
							)}
						</>
					) : currentAction === 'changeRole' ? (
						<>
							<p>
								Hva skal den nye rollen til{' '}
								{userToEdit?.first_name} {userToEdit?.last_name}{' '}
								være?
							</p>
							<div className='radio'>
								<label>
									<input
										type='radio'
										value='user'
										checked={selectedRole === 'user'}
										onChange={(e) =>
											setSelectedRole(e.target.value)
										}
									/>
									User
								</label>
								<label>
									<input
										type='radio'
										value='admin'
										checked={selectedRole === 'admin'}
										onChange={(e) =>
											setSelectedRole(e.target.value)
										}
									/>
									Admin
								</label>
								{userRole === 'superAdmin' && (
									<label>
										<input
											type='radio'
											value='superAdmin'
											checked={
												selectedRole === 'superAdmin'
											}
											onChange={(e) =>
												setSelectedRole(e.target.value)
											}
										/>
										Super Admin
									</label>
								)}
							</div>
						</>
					) : null
				}
			/>
		</div>
	);
};

export default Users;
