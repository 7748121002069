import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';

import '../assets/scss/checkboxComponent.scss';

const CheckboxComponent = ({
	label,
	name,
	value,
	className,
	subContext,
	register,
	...rest
}) => {
	return (
		<>
			<div className={`checkbox-container ${className}`}>
				<input
					type='checkbox'
					name={name}
					id={name}
					value={value}
					className='checkbox-card-input'
					{...register(name)}
					{...rest}
				/>
				<label htmlFor={value} className={`checkbox-card-label`}>
					{label}
				</label>
			</div>
			{subContext && (
				<div className='checkboxSubContext'>
					<small>
						{subContext} <FaExclamationCircle size='28' />
					</small>
				</div>
			)}
		</>
	);
};

export default CheckboxComponent;
