import React from 'react';
import '../../../assets/scss/Admin/Users.scss';

const ConfirmationModal = ({
	isOpen,
	onClose,
	onConfirm,
	children,
	content,
}) => {
	if (!isOpen) return null;

	return (
		<div className='modal-backdrop' onClick={onClose}>
			<div className='modal-adm' onClick={(e) => e.stopPropagation()}>
				<div className='modal-content'>
					{children}
					{content}
				</div>
				<div className='modal-actions'>
					<button className='cancel' onClick={onClose}>
						Avbryt
					</button>
					<button className='confirm' onClick={onConfirm}>
						Bekreft
					</button>
				</div>
			</div>
		</div>
	);
};

export default ConfirmationModal;
