import React, { useEffect, useState } from 'react';
import SectionFive from '../pageSections/SectionFive';
import SectionFour from '../pageSections/SectionFour';
import SectionOne from '../pageSections/SectionOne';
import SectionSeven from '../pageSections/SectionSeven';
import SectionSix from '../pageSections/SectionSix';
import SectionThree from '../pageSections/SectionThree';
import SectionTwo from '../pageSections/SectionTwo';

const FormContent = ({
	control,
	register,
	handleChange,
	formData,
	setValue,
	selectedLocation,
	handleLocationSelect,
	selectedServices,
	setSelectedServices,
	getValues,
	preselectedLocation,
	sending,
}) => {
	useEffect(() => {
		console.log(formData);
	}, [formData]);
	console.log(formData);
	const [currentSection, setCurrentSection] = useState(0);

	const nextSection = () => {
		setCurrentSection((prev) => prev + 1);
	};
	const prevSection = () => {
		setCurrentSection((prev) => prev - 1);
	};

	return (
		<div className='mainContent'>
			<SectionOne
				control={control}
				register={register}
				formData={formData}
				setValue={setValue}
				handleLocationSelect={handleLocationSelect}
				handleChange={handleChange}
				preselectedLocation={preselectedLocation}
			/>

			<SectionTwo
				register={register}
				formData={formData}
				setValue={setValue}
				control={control}
				selectedLocation={selectedLocation}
				selectedServices={selectedServices}
				setSelectedServices={setSelectedServices}
				getValues={getValues}
			/>

			<SectionThree
				register={register}
				formData={formData}
				handleChange={handleChange}
			/>

			<SectionFour
				register={register}
				formData={formData}
				handleChange={handleChange}
			/>

			<SectionFive
				setValue={setValue}
				register={register}
				formData={formData}
				control={control}
			/>

			<SectionSix register={register} formData={formData} />

			<SectionSeven
				control={control}
				register={register}
				formData={formData}
				sending={sending}
			/>
		</div>
	);
};

export default FormContent;
