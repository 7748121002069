import axios from 'axios';
import 'chart.js/auto'; // Import to register the controller and chart type.
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

const BarChartComponent = () => {
	const [chartData, setChartData] = useState({
		labels: ['Mediumliten', 'Mediumstor', 'Premium'],
		datasets: [
			{
				data: [0, 0, 0], // Initial dummy data
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 206, 86, 0.2)',
				],
				borderColor: [
					'rgba(255, 99, 132, 1)',
					'rgba(54, 162, 235, 1)',
					'rgba(255, 206, 86, 1)',
				],
				borderWidth: 1,
			},
		],
	});

	useEffect(() => {
		const fetchChartData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/statistics/monthly-order-stats`
				);
				const { mediumLitenCount, mediumStorCount, premiumCount } =
					response.data;

				setChartData({
					labels: ['Mediumliten', 'Mediumstor', 'Premium'],
					datasets: [
						{
							data: [
								mediumLitenCount,
								mediumStorCount,
								premiumCount,
							],
							backgroundColor: [
								'rgba(255, 99, 132, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(255, 206, 86, 0.2)',
							],
							borderColor: [
								'rgba(255, 99, 132, 1)',
								'rgba(54, 162, 235, 1)',
								'rgba(255, 206, 86, 1)',
							],
							borderWidth: 1,
						},
					],
				});
			} catch (error) {
				console.error('Error fetching chart data:', error);
			}
		};

		fetchChartData();
	}, []);

	const options = {
		plugins: {
			legend: {
				display: false,
			},
		},
		scales: {
			y: {
				beginAtZero: true,
			},
		},
	};

	return <Bar data={chartData} options={options} />;
};

export default BarChartComponent;
