import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BarChartComponent from './data/dashChartData';

// Icons
import { BsGraphUp } from 'react-icons/bs';
import { FaRegMoneyBillAlt, FaWpforms } from 'react-icons/fa';
import { LuUser2 } from 'react-icons/lu';
import { RiAdminLine } from 'react-icons/ri';

import { TiStarFullOutline } from 'react-icons/ti';
// SCSS
import '../../../assets/scss/Admin/Dashboard.scss';

const Dashboard = () => {
	const [stats, setStats] = useState({
		users: 0,
		userRoleUser: 0,
		userRoleAdmin: 0,
		ordersSent: 0,
		formsMonth: 0,
		mostFirm: '',
		userMostOrders: '',
	});
	const [reviews, setReviews] = useState([]);

	const apiUrl = process.env.REACT_APP_API_URL;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [userStats, orderStats, monthlyStats] = await Promise.all(
					[
						axios.get(`${apiUrl}/statistics/user-stats`),
						axios.get(`${apiUrl}/statistics/order-stats`),
						axios.get(`${apiUrl}/statistics/monthly-order-stats`),
					]
				);

				setStats({
					users: parseInt(userStats.data.userCount),
					userRoleUser: parseInt(userStats.data.userCount),
					userRoleAdmin: parseInt(
						userStats.data.adminAndSuperAdminCount
					),
					ordersSent: parseInt(orderStats.data.totalOrders),
					formsMonth: parseInt(monthlyStats.data.monthlyOrderCount),
					mostFirm: monthlyStats.data.mostFrequentCompanyName,
					userMostOrders: monthlyStats.data.mostFrequentOrdererName,
				});
			} catch (error) {
				console.error('Error fetching dashboard data:', error);
			}
		};

		const fetchReviews = async () => {
			try {
				const response = await axios.get(
					`${apiUrl}/reviews/getReviews`
				);
				setReviews(response.data);
				console.log(response.data);
			} catch (error) {
				console.error('Error fetching reviews:', error);
			}
		};

		fetchData();
		fetchReviews();
	}, [apiUrl]);

	return (
		<div className='dashboard'>
			<h3>Du er nå inne i Admin oversikten</h3>
			<div className='dashboard__content'>
				<div className='topStats'>
					<div className='stats-card'>
						<div className='col'>
							<LuUser2 size='32' />
							<h3>Brukere registrert</h3>
						</div>
						<div className='stats-number'>
							<p>{stats.users}</p>
						</div>
					</div>

					<div className='stats-card'>
						<div className='col'>
							<RiAdminLine size='32' />
							<h3>Admin brukere</h3>
						</div>
						<div className='stats-number'>
							<p>{stats.userRoleAdmin}</p>
						</div>
					</div>

					<div className='stats-card'>
						<div className='col'>
							<FaWpforms size='32' />
							<h3>Ordre sendt</h3>
						</div>
						<div className='stats-number'>
							<p>{stats.ordersSent}</p>
						</div>
					</div>
				</div>

				<div className='middleStats'>
					<div className='middleStats__content row'>
						<div className='dailyReports'>
							<div className='top row'>
								<div className='col'>
									<h3>Månedlig rapport</h3>
									<p>The Assessment Company</p>
								</div>
								<BsGraphUp size='32' />
							</div>
							<div className='dailyReports__content'>
								<BarChartComponent />
							</div>
						</div>

						<div className='statsReport'>
							<div className='top row'>
								<div className='col'>
									<h3>Statistikk denne mnd</h3>
									<p>The Assessment Company</p>
								</div>
								<FaRegMoneyBillAlt size='32' />
							</div>
							<div className='statsReport__content'>
								<div className='row'>
									<div className='col'>
										<div className='col new-item'>
											<h3>Antall skjema</h3>
											<p>{stats.formsMonth}</p>
										</div>
									</div>
									<div className='col'>
										<div className='col new-item'>
											<h3>Største firma</h3>
											<p>{stats.mostFirm}</p>
										</div>
										<div className='col new-item'>
											<h3>Flest bestillinger</h3>
											<p>{stats.userMostOrders}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='review-stats'>
					<h2>Tilbakemeldinger fra kunde</h2>
					<div className='review-stats__content'>
						{reviews.map((review) => (
							<div className='review' key={review.id}>
								<div className='flex-row'>
									<div className='annonselosninger'>
										<p>Annonseløsninger</p>
										<p>
											{review.rating_annonseløsninger} av
											5 <TiStarFullOutline />
										</p>
									</div>
									<div className='bestillingsskjema'>
										<p>Bestillingsskjema</p>
										<p>
											{review.rating_bestillingsskjema} av
											5 <TiStarFullOutline />
										</p>
									</div>
								</div>
								<div className='text'>{review.review_text}</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
