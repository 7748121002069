import React, { useEffect, useState } from 'react';
import { FaRegFilePdf } from 'react-icons/fa6';
import '../../assets/scss/profile.scss';

const Profile = () => {
	const apiUrl = process.env.REACT_APP_API_URL;
	const [userInfo, setUserInfo] = useState(null);
	const [error, setError] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [activeSection, setActiveSection] = useState('userInfo');
	const [profileMessage, setProfileMessage] = useState('');
	const [passwordData, setPasswordData] = useState({
		currentPassword: '',
		newPassword: '',
		confirmNewPassword: '',
	});
	const [passwordMessage, setPasswordMessage] = useState('');
	const [userPDFs, setUserPDFs] = useState([]);

	const offices = [
		'Oslo',
		'Hamar',
		'Trondheim',
		'Molde',
		'Ålesund',
		'Stavanger',
	];

	useEffect(() => {
		const fetchUserInfo = async () => {
			try {
				const token = localStorage.getItem('token');
				if (!token) {
					setError('No token found');
					return;
				}

				const base64Url = token.split('.')[1];
				const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
				const jsonPayload = decodeURIComponent(
					atob(base64)
						.split('')
						.map((c) => {
							return (
								'%' +
								('00' + c.charCodeAt(0).toString(16)).slice(-2)
							);
						})
						.join('')
				);

				const user = JSON.parse(jsonPayload);
				const userId = user.id;

				const response = await fetch(`${apiUrl}/auth/profile`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ userId }),
				});

				const data = await response.json();
				if (response.ok) {
					setUserInfo(data);
					fetchUserPDFs(userId, token);
				} else {
					setError(
						data.message || 'Failed to fetch user information'
					);
				}
			} catch (error) {
				setError('An error occurred while fetching user information.');
			}
		};

		const fetchUserPDFs = async (userId, token) => {
			try {
				const response = await fetch(`${apiUrl}/pdfs/user-pdfs`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ userId }),
				});

				const data = await response.json();
				if (response.ok) {
					setUserPDFs(data);
				} else {
					setError(data.message || 'Failed to fetch user PDFs');
				}
			} catch (error) {
				setError('An error occurred while fetching user PDFs.');
			}
		};

		fetchUserInfo();
	}, [apiUrl]);

	const handleEditClick = () => {
		setIsEditing(true);
	};

	const handleAbortClick = () => {
		setIsEditing(false);
	};

	const handleConfirmClick = async () => {
		try {
			const token = localStorage.getItem('token');
			if (!token) {
				setError('No token found');
				return;
			}

			const userId = userInfo.id;

			const response = await fetch(`${apiUrl}/auth/users/${userId}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(userInfo),
			});

			const data = await response.json();
			if (response.ok) {
				setUserInfo(data);
				setIsEditing(false);
				setProfileMessage('Informasjonen din er oppdatert');
			} else {
				setError(data.message || 'Failed to update user information');
			}
		} catch (error) {
			setError('An error occurred while updating user information.');
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setUserInfo((prevInfo) => ({
			...prevInfo,
			[name]: value,
		}));
	};

	const handlePasswordChange = (e) => {
		const { name, value } = e.target;
		setPasswordData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handlePasswordSubmit = async (e) => {
		e.preventDefault();
		try {
			const token = localStorage.getItem('token');
			if (!token) {
				setError('No token found');
				return;
			}

			const userId = userInfo.id;

			const response = await fetch(
				`${apiUrl}/auth/users/${userId}/password`,
				{
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(passwordData),
				}
			);

			const data = await response.json();
			if (response.ok) {
				setPasswordMessage('Passordet er oppdatert');
				setPasswordData({
					currentPassword: '',
					newPassword: '',
					confirmNewPassword: '',
				});
			} else {
				setPasswordMessage(data.message || 'Failed to update password');
			}
		} catch (error) {
			setPasswordMessage('An error occurred while updating password.');
		}
	};

	if (error) {
		return <p>{error}</p>;
	}

	if (!userInfo) {
		return <p>Loading...</p>;
	}

	const currentOffice = userInfo.department;
	const otherOffices = offices.filter((office) => office !== currentOffice);

	return (
		<div className='profile'>
			<h1>{userInfo.first_name} sin profil</h1>
			<div className='profileOptions'>
				<h3>
					Velg informasjonen du vil vise. Du kan her oppdatere din
					informasjon som for eksempel epost, telefonnummer, navn
					eller passord.
				</h3>
				<div className='profileBtns'>
					<button onClick={() => setActiveSection('userInfo')}>
						Se din informasjon
					</button>
					<button
						onClick={() =>
							setActiveSection('profileChangePassword')
						}
					>
						Oppdater passord
					</button>
					<button onClick={() => setActiveSection('profileOrders')}>
						Se dine tidligere ordre
					</button>
				</div>
			</div>
			<div className='profileRenders'>
				{activeSection === 'userInfo' && (
					<div className='userInfo'>
						<h2>Du kan her se og endre brukerinformasjonen din.</h2>
						<form>
							<div className='column'>
								<label htmlFor='profileUsername'>
									Brukernavn
								</label>
								<input
									type='text'
									id='profileUsername'
									value={userInfo.username}
									readOnly
								/>
							</div>
							<div className='row'>
								<div className='column'>
									<label htmlFor='profileFirstName'>
										Fornavn
									</label>
									<input
										type='text'
										id='profileFirstName'
										name='first_name'
										value={userInfo.first_name}
										onChange={handleChange}
										readOnly={!isEditing}
									/>
								</div>
								<div className='column'>
									<label htmlFor='profileLastName'>
										Etternavn
									</label>
									<input
										type='text'
										id='profileLastName'
										name='last_name'
										value={userInfo.last_name}
										onChange={handleChange}
										readOnly={!isEditing}
									/>
								</div>
							</div>
							<div className='row'>
								<div className='column'>
									<label htmlFor='profileEmail'>Epost</label>
									<input
										type='text'
										id='profileEmail'
										name='email'
										value={userInfo.email}
										onChange={handleChange}
										readOnly={!isEditing}
									/>
								</div>
								<div className='column'>
									<label htmlFor='profilePhone'>
										Telefonnummer
									</label>
									<input
										type='text'
										id='profilePhone'
										name='telephone'
										value={userInfo.telephone}
										onChange={handleChange}
										readOnly={!isEditing}
									/>
								</div>
							</div>
							<div className='row'>
								<div className='column'>
									<label htmlFor='profileDepartment'>
										Kontor
									</label>
									<select
										name='department'
										id='profileDepartment'
										value={currentOffice || ''}
										onChange={handleChange}
										disabled={!isEditing}
									>
										<option value='' disabled hidden>
											Velg kontor
										</option>
										{offices.map((office) => (
											<option key={office} value={office}>
												{office}
											</option>
										))}
									</select>
								</div>
							</div>
						</form>
						{profileMessage && (
							<div className='profileMsg'>{profileMessage}</div>
						)}
						<div className='editButtons'>
							{isEditing ? (
								<>
									<button
										className='confBtn'
										onClick={handleConfirmClick}
									>
										Bekreft endring
									</button>
									<button
										className='abortBtn'
										onClick={handleAbortClick}
									>
										Avbryt
									</button>
								</>
							) : (
								<button
									className='changeBtn'
									onClick={handleEditClick}
								>
									Endre info
								</button>
							)}
						</div>
					</div>
				)}
				{activeSection === 'profileChangePassword' && (
					<div className='profileChangePassword'>
						<h2>Her kan du endre ditt passord.</h2>
						<form onSubmit={handlePasswordSubmit}>
							<div>
								<label htmlFor='profileCurrentPassword'>
									Nåværende passord
								</label>
								<input
									type='password'
									id='profileCurrentPassword'
									name='currentPassword'
									value={passwordData.currentPassword}
									onChange={handlePasswordChange}
								/>
							</div>
							<div>
								<label htmlFor='profileNewPassword'>
									Nytt passord
								</label>
								<input
									type='password'
									id='profileNewPassword'
									name='newPassword'
									value={passwordData.newPassword}
									onChange={handlePasswordChange}
								/>
							</div>
							<div>
								<label htmlFor='profileConfirmPassword'>
									Bekreft passord
								</label>
								<input
									type='password'
									id='profileConfirmPassword'
									name='confirmNewPassword'
									value={passwordData.confirmNewPassword}
									onChange={handlePasswordChange}
								/>
							</div>
							<button type='submit'>Oppdater passord</button>
						</form>
						{passwordMessage && (
							<div className='passwordMsg'>{passwordMessage}</div>
						)}
					</div>
				)}
				{activeSection === 'profileOrders' && (
					<div className='profileOrders'>
						<h2>DIne innsendte ordre</h2>
						<table>
							<thead>
								<tr>
									<th>PDF Navn</th>
									<th>Opprettet</th>
									<th>PDF</th>
								</tr>
							</thead>
							<tbody>
								{userPDFs.map((pdf) => (
									<tr key={pdf.id}>
										<td>{pdf.pdf_name}</td>
										<td>
											{new Date(
												pdf.created_at
											).toLocaleString()}
										</td>
										<td>
											<a
												href={`data:application/pdf;base64,${pdf.file_data}`}
												download={pdf.pdf_name}
												target='_blank'
												rel='noopener noreferrer'
											>
												<FaRegFilePdf size='20' />
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
			</div>
		</div>
	);
};

export default Profile;
