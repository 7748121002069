import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import LogoutButton from './LogoutButton';

import { FaUserCircle } from 'react-icons/fa';
import { FiPhoneCall } from 'react-icons/fi';

// Import images
import aclogo from '../assets/images/aclogo.svg';
import adrlogo from '../assets/images/alogo.svg';
import pmedia from '../assets/images/polarismedia_logo_neg.svg';

const Header = () => {
	const { isLoggedIn, userRole } = useContext(AuthContext);
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);

	const dropdownRef = useRef(null);

	const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);

	useEffect(() => {
		function handleClickOutside(event) {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setIsDropdownVisible(false);
			}
		}

		if (isDropdownVisible) {
			document.addEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isDropdownVisible]);

	return (
		<div className='main-header'>
			<div className='header-content'>
				<div className='ac-logo'>
					<img src={aclogo} alt='' />
				</div>
				<div className='menuItems'>
					<ul>
						<li>
							<Link to='/'>Skjema</Link>
						</li>
						{userRole === 'admin' || userRole === 'superAdmin' ? (
							<li>
								<Link to='/admin'>Admin</Link>
							</li>
						) : null}
					</ul>
				</div>
				<div className='userIcon'>
					<ul>
						{isLoggedIn ? (
							<>
								<li>
									<Link to='/profile'>Profil</Link>
								</li>
								{userRole === 'admin' ||
								userRole === 'superAdmin' ? (
									<li>
										<Link to='/orders'>Ordrer</Link>
									</li>
								) : null}
								<li>
									<LogoutButton />
								</li>
							</>
						) : (
							<li>
								<Link to='/login'>Logg inn</Link>
							</li>
						)}
					</ul>
				</div>
				<div className='alogo-section'>
					<div className='contact'>
						<h2>Janne Løvseth</h2>
						<div>
							<FiPhoneCall size='16' />{' '}
							<a href='tel:97715766'>977 15 766</a>
						</div>
					</div>
					<div className='border'></div>
					<img src={pmedia} alt='pmedia' />
				</div>
			</div>
		</div>
	);
};

export default Header;
