import React, { useState } from 'react';
import '../assets/scss/textareaComponent.scss';

const TextareaComponent = ({
	label,
	name,
	className,
	placeholder,
	required,
	register,
	cols,
	rows,
	counter,
	maxLength = 120, // Recommended maxLength
	...rest
}) => {
	const [charactersTyped, setCharactersTyped] = useState(0);

	// Update character count based on textarea input
	const handleInputChange = (event) => {
		setCharactersTyped(event.target.value.length);
	};

	return (
		<div
			className={`textarea-container ${className} ${
				counter ? 'with-counter' : ''
			}`}
		>
			<label htmlFor={name}>
				{label}
				{required && (
					<span style={{ color: 'red' }} className='required'>
						*
					</span>
				)}
			</label>
			<textarea
				name={name}
				id={name}
				cols={cols}
				rows={rows}
				required={required}
				placeholder={placeholder}
				{...register(name, { required })}
				onInput={handleInputChange}
				{...rest}
			></textarea>
			{counter && (
				<div className='char-counter'>
					{charactersTyped} / {maxLength}
				</div>
			)}
		</div>
	);
};

export default TextareaComponent;
