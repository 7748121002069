import React, { useEffect, useState } from 'react';
import '../assets/scss/buttonSelection.scss';

const locations = [
	'Oslo',
	'Hamar',
	'Trondheim',
	'Molde',
	'Ålesund',
	'Stavanger',
];

const ButtonSelection = ({ onSelect, preselectedLocation }) => {
	const [selectedLocation, setSelectedLocation] =
		useState(preselectedLocation);

	useEffect(() => {
		if (preselectedLocation) {
			setSelectedLocation(preselectedLocation);
		}
	}, [preselectedLocation]);

	const handleSelect = (location) => {
		setSelectedLocation(location);
		onSelect(location);
	};

	return (
		<div className='buttonSelection'>
			<div className='btn-containers'>
				{locations.map((location) => (
					<button
						key={location}
						type='button'
						className={
							selectedLocation === location ? 'selected' : ''
						}
						onClick={() => handleSelect(location)}
					>
						{location}
					</button>
				))}
			</div>
		</div>
	);
};

export default ButtonSelection;
